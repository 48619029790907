// 首页接口
import request from "../request.js";
// 获取平台以及账号数量基本情况
function getPlatformAccountNum() {
  return request({
    url: "module-web/statistics/accountStat",
    method: "post",
    data: {
      aggField: "platform_type",
      dictField: "platform_type",
    },
  });
}
// 平台及账号发布信息数量情况
function getPlatformAccountRelease(data) {
  return request({
    url: "module-web/statistics/releaseSituation",
    method: "post",
    data,
  });
}
// 专项行动列表
function getPlanSpecialList(data) {
  return request({
    url: "module-web/statistics/planSpecialList",
    method: "post",
    data,
  });
}
// 检测方案列表
function getPlanMonitorList() {
  return request({
    url: "module-web/statistics/planPlanMonitorList",
    method: "post",
    data: {
      pageNum: 1,
      pageSize: 10,
    },
  });
}
// 获取异常分析中的平台账号异常
export function getAbnormalNum(data) {
  return request({
    url: "module-web/statistics/accountStat",
    method: "post",
    data,
  });
}
// 获取异常分析有害信息
function getAnomalyAnalysis(data) {
  return request({
    url: "module-web/statistics/anomalyAnalysis",
    method: "post",
    data,
  });
}
// 获取异常分析错误表述
function getAnomalyAnalysisErr(data) {
  return request({
    url: "module-web/statistics/misreAnomalyAnalysis",
    method: "post",
    data,
  });
}
// 获取有害信息数量情况
export function getHarmfulInfNum(data) {
  return request({
    url: "module-web/statistics/harmfulDataPlatform",
    method: "post",
    data,
  });
}
// 获取预警信息列表
function getWarningInformation(data) {
  return request({
    url: "module-system/sysMessage/list",
    method: "post",
    data,
  });
}
//预警信息-----错误表述
function getErrorExpressionList(data) {
  return request({
    url: "module-listener-complex/planMisreData/list",
    method: "post",
    data,
  });
}
//预警信息-----异常状态???????
function queryPage(data) {
  return request({
    url: "/module-web/abnormalPlatform/queryPage",
    method: 'post',
    data
  })
}
//预警信息-----有害信息
function getlist(data) {
  return request({
    url: "module-listener-complex/harmfulData/list",
    method: "post",
    data
  })
}
//预警信息-----监测方案
function planMonitorDataList(data) {
  return request({
    url: "module-polyse/planMonitorData/planMonitorDataList",
    method: 'post',
    data
  })
}
// 首页台账统计近30日问题网站
function thirtyDayProblemWebsite(data) {
  return request({
    url: "module-account/account/thirtyDayProblemWebsite",
    method: 'post',
    data
  })
}

function exportThirtyDayProblemWebsite(data) {
  return request({
    url: "module-account/account/exportThirtyDayProblemWebsite ",
    method: 'post',
    data
  })
}
// 已注销台账
function writeOffAccount(data) {
  return request({
    url: "module-account/account/writeOffAccount",
    method: 'post',
    data
  })
}
// 政务类账号月发文排行
function rankingOfMonthly(data) {
  return request({
    url: "module-account/account/rankingOfMonthly",
    method: 'post',
    data
  })
}
function exportRankingOfMonthly(data) {
  return request({
    url: "module-account/account/exportRankingOfMonthly",
    method: 'post',
    data
  })
}
//  僵尸号
function threeMonthsNoMessage(data) {
  return request({
    url: "module-account/account/threeMonthsNoMessage",
    method: 'post',
    data
  })
}

function exportThreeMonthsNoMessage(data) {
  return request({
    url: "module-account/account/exportThreeMonthsNoMessage",
    method: 'post',
    data
  })
}

export default {
  getPlatformAccountNum,
  getPlatformAccountRelease,
  getPlanSpecialList,
  getPlanMonitorList,
  getAbnormalNum,
  getAnomalyAnalysis,
  getWarningInformation,
  getHarmfulInfNum,
  getAnomalyAnalysisErr,
  //修改预警信息-------------------
  getErrorExpressionList,
  queryPage,
  getlist,
  planMonitorDataList,
  thirtyDayProblemWebsite,
  writeOffAccount,
  rankingOfMonthly,
  threeMonthsNoMessage,
  exportThirtyDayProblemWebsite,
  exportRankingOfMonthly,
  exportThreeMonthsNoMessage
};
