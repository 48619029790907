<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title><Title>已注销台账</Title></template>
             <template v-slot:icon>
        <el-tooltip placement="top" content="已注销ICP备案号的网站（只统计本月）" >
            <img style="width:16px;height:16px;margin-left:10px" src="@/assets/img/icon/tishi.png" alt="" srcset="">
</el-tooltip>
    </template>
      <template v-slot:com>
        <TitleNext :desc="'更多'" @click.native="goMonitoringList" />
      </template>
    </TitleCard>
    <el-table
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      style="width: 100%"
    v-loading="tabelLoading"
    height="520"
    >
       <el-table-column prop="platformlypeName" label="平台类型"> 
          <template slot="default" slot-scope="scope">
              <div class="work-cell">
                      <img :src="require(`@/assets/img/xiaoPingTai/${scope.row.platformType}.png`)" width="16" height="16"> 
                                    <span style="margin-left:5px">{{scope.row.platformlypeName}}</span>
              </div>
            </template>
       </el-table-column>
      <el-table-column prop="bodyTypeName" label="主体类型"> 
         <template slot="header" slot-scope="scope">
          <el-dropdown @command="handleClickZhuTi">
            <span class="cursor">
               {{zhutiName}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, inx) in bodyLeiXin"
                :key="inx"
                :command="item.value"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
        <el-table-column prop="accountName" label="账号名称"> </el-table-column>
    </el-table>
    <!-- 弹出框 -->
    <el-dialog title="已注销数据" :visible.sync="dialogTableVisible">
    <el-table :data="totalData"  :header-cell-style="{ 'text-align': 'center' }" :close-on-click-modal = "false"
      :cell-style="{ 'text-align': 'center' }" v-loading="dialogLoading">
      <el-table-column prop="bodyTypeName" label="主体类型"> 
      </el-table-column>
        <el-table-column prop="accountName" label="账号名称"> </el-table-column>
      </el-table>
            <Pagination
        :pageNumber="page.pageNo"
        :pageSize="page.pageSize"
        :total="page.total"
        @SizeChange="SizeChange"
        @CurrentChange="CurrentChange"
      >
      </Pagination>
</el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import TitleCard from "../../../components/TitleCard";
import Title from "../../../base/Title";
import TitleNext from "../../../base/TitleNext";
import Index from "@/api/index/index";
import mixins from './minxin.js'
export default {
  props: ["bodyLeiXin"],
    mixins:[mixins()],
  components: { TitleCard, Title, TitleNext,Pagination },
  data() {
    return {
      tableData: [],
      dialogTableVisible:false,
        page: {
        pageNo: 1,
        pageSize: 10,
        total:0
      },
      totalData:[],
      dialogLoading:false
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo(data={}) {
        this.tabelLoading = true
        const Res = await Index.writeOffAccount({
          "pageNo": 1,
          "pageSize": 10,
          ...data
        })
         this.tableData = Res.data.data.list;
   if(Object.keys(data).length === 0) this.totalData = this.tableData
          this.page.total = Res.data.data.totalCount /1
            this.tabelLoading = false
    },
    goMonitoringList() {
      this.dialogTableVisible = true
    },
    async searchFn() {
      this.dialogLoading = true
const Res = await Index.writeOffAccount({
          "pageNo": 1,
          "pageSize": 10,
        })
         this.totalData = Res.data.data.list
              this.page.total = Res.data.data.totalCount /1
               this.dialogLoading = false
    },
   SizeChange(val) {
      this.page.pageSize  = val;
      this.searchFn();
    },
  CurrentChange(val) {
      this.page.pageNo= val;
      this.searchFn();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td.el-table__cell {
  border-bottom: none !important;
}
.centered-table .cell {
  text-align: center;
  vertical-align: middle;
}
</style>
