<template>
  <div class="page">
    <el-row :gutter="24">
      <!-- 平台以及账号数量基本情况 -->
      <el-col :span="14">
        <NumberAccounts />
      </el-col>
      <!-- 预警信息 -->
      <el-col :span="10">
        <WarningInformation />
      </el-col>
    </el-row>
    <!-- <el-row :gutter="24" class="m-t-big">
        <el-col :span="12">
        <Nearly30QuestionsVue />
      </el-col>
        <el-col :span="12">
          <zhengwuYueFaWenVue :PTLXList="pinTaiLeiXin" :bodyLeiXin= "bodyLeiXin" />
      </el-col>
    </el-row>
        <el-row :gutter="24" class="m-t-big">
        <el-col :span="12">
        <yizhuxiaoVue :bodyLeiXin= "bodyLeiXin"/>
      </el-col>
        <el-col :span="12">
          <jiangshihaoVue  :PTLXList="pinTaiLeiXin" />
      </el-col>
    </el-row> -->
    <!-- 平台及账号发布信息数量情况 -->
    <!-- 异常分析 -->
    <el-row :gutter="24" class="m-t-big">

      <el-col :span="14">
        <Quantity />
      </el-col>
  
      <el-col :span="10">
        <BasicVue />
      </el-col>
    </el-row>
    <!-- 专项行动 -->
    <!-- 监测方案 -->
    <!-- 有害信息数量情况 -->
    <el-row :gutter="24" class="m-t-big">
     
      <el-col :span="11">
        <Special />
      </el-col>
      
      <el-col :span="6">
        <Monitoring />
      </el-col>
    
      <el-col :span="7">
        <HarmfulVue />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import NumberAccounts from "./components/NumberAccounts"; //平台以及账号数量基本情况
import WarningInformation from "./components/WarningInformation"; //预警信息
import Quantity from "./components/Quantity"; // 异常分析
import BasicVue from "./components/Basic"; // 平台及账号发布信息数量情况
import Special from "./components/Special"; // 专项行动
import Monitoring from "./components/Monitoring"; // 监测方案
import HarmfulVue from "./components/harmful"; // 有害信息列表
import { Dictionaries } from "@/api/platformaccount/index";
import Nearly30QuestionsVue from './components/Nearly30Questions.vue';
import zhengwuYueFaWenVue from './components/zhengwuYueFaWen.vue';
import yizhuxiaoVue from './components/yizhuxiao.vue';
import jiangshihaoVue from './components/jiangshihao.vue';
export default {
  data() {
    return {
      pinTaiLeiXin:[
        // {name:'网站',value:1},
        // {name:"微信公众号",value:2},
        // {name:'微博',value:1},
        // {name:"抖音",value:2},
        // {name:'今日头条',value:1},
      ],
      bodyLeiXin:[

      ]
    };
  },
  components: {
    NumberAccounts,
    WarningInformation,
    Quantity,
    BasicVue,
    Special,
    Monitoring,
    HarmfulVue,
    Nearly30QuestionsVue,
    zhengwuYueFaWenVue,
    yizhuxiaoVue,
    jiangshihaoVue
  },
  created() {
      this.getDictionariesList();
  },
  mounted() {
  
  },
  methods: {
    // 请求字典
    getDictionariesList() {
      let that = this;
      const todata = (data) =>
        data.map((item) => ({ label: item.label, value: item.value }));
      // 处置方式
      Dictionaries("harmful_dispose_mode").then((Res) => {
        window.localStorage.setItem(
          "harmful_dispose_mode",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 处置结果
      Dictionaries("harmful_dispose_result").then((Res) => {
        window.localStorage.setItem(
          "harmful_dispose_result",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 平台类型
      Dictionaries("platform_type").then((Res) => {
        window.localStorage.setItem(
          "platform_type",
          JSON.stringify(todata(Res.data.data))
        );
        that.pinTaiLeiXin = todata(Res.data.data)
      });
      // 主体类型
      Dictionaries("body_type").then((Res) => {
        window.localStorage.setItem(
          "body_type",
          JSON.stringify(todata(Res.data.data))
        );
        this.bodyLeiXin = todata(Res.data.data)
      });
      // 信息类型
      Dictionaries("message_type").then((Res) => {
        window.localStorage.setItem(
          "message_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 认证情况
      Dictionaries("is_authenticate").then((Res) => {
        window.localStorage.setItem(
          "is_authenticate",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 语言种类
      Dictionaries("language_category").then((Res) => {
        window.localStorage.setItem(
          "language_category",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 主办单位性质
      Dictionaries("organizer_type").then((Res) => {
        window.localStorage.setItem(
          "organizer_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 前置审批
      Dictionaries("pre_approval").then((Res) => {
        window.localStorage.setItem(
          "pre_approval",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 国家
      Dictionaries("access_country").then((Res) => {
        window.localStorage.setItem(
          "access_country",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 境内外接入
      Dictionaries("access_way").then((Res) => {
        window.localStorage.setItem(
          "access_way",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 微信公众号-处置状态
      Dictionaries("wechat_dispose_state").then((Res) => {
        window.localStorage.setItem(
          "wechat_dispose_state",
          JSON.stringify(todata(Res.data.data))
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
