// 专项行动
<template>
  <div>
    <!-- 标题栏 --> 
    <TitleCard>
      <template v-slot:title><Title>专项行动</Title></template>
      <template v-slot:com>
        <TitleNext :desc="'全部行动'" @click.native="goSpecialActionsList" />
      </template>
    </TitleCard>
    <!-- 内容 -->
    <el-card shadow="never"  v-loading="specialActionsLoading">
     
        <div class="news-box"  style="height: 240px">
          <template v-if="newsdata.length > 0">
        <div
          v-for="(item, index) in newsdata"
          :key="index"
          :news="item" 
          class="newslist"
          @click="goDetail(item)"
        >
          <div class="newslist-left ">{{ item.name }}</div>
          <div class="newslist-time m-l-16">
            {{ item.startTime + " - " + (item.status == 2 ? '至今':item.endTime) }}
          </div>
          <div class="newslist-right m-l-16">
            <span class="num">{{ (item.data && item.data.count) || 0 }}</span>
            <span class="num2 m-l">
              <img src="../../../assets/img/icon/top.png" alt="" srcset="" />
              {{ (item.data && item.data.todayCount) || 0 }}
            </span>
          </div>
        </div>
      </template>
      <div class="analyse-container" v-else>
    <div class="analyse-container-img">
      <img src="@/assets/img/gjh/nozx.png" style="width:200px"/>
      <p>专项行动暂无数据</p>
    </div>
  </div>
      </div>
      
   
    </el-card>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import Index from "@/api/index/index";
import TitleNext from "../../../base/TitleNext";
import Title from "../../../base/Title";
export default {
  components: {
    TitleCard,
    Title,
    TitleNext,
  },
  data() {
    return {
      specialActionsLoading: false, //加载效果
      newsdata: [], // 信息列表里的数据
    };
  },
  methods: {
    // 专项行动首页列表
    async getPlanSpecialList() {
      this.specialActionsLoading = true;
      const res = await Index.getPlanSpecialList({
        limit: 10,
        page: 1,
      });
      if (res.data.code == 200) {
        this.newsdata = res.data.data;
        this.newsdata.forEach((x) => {
          if (!x.endTime) {
            x.endTime = "至今";
          }
        });
        this.specialActionsLoading = false;
      }
    },
    // 进入详情页
    goDetail(item) {
      this.$router.push({
        path: "/topicListDetails",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.id,
              title: item.name,
              item: item,
            })
          ),
        },
      });
    },
    // 跳转专项行动
    goSpecialActionsList() {
      this.$router.push({
        path: "/special",
      });
    },
  },
  created() {
    this.getPlanSpecialList();
  },
};
</script>

<style lang="scss" scoped>
 
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
.news-box {
  height: 240px;
  box-sizing: border-box;
   /* 暂无数据用的 */
   .analyse-container {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    // margin-top: 60px;
    // text-align: center;
    padding-top: 60px;
    .analyse-container-img {
      text-align: center;
  }
  
  .analyse-container-img p {
    font-size: 14px;
    color: #999999;
  }
  }
 
  .newslist {
    padding: 15px 16px 15px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333746;
    font-size: 14px;
    cursor: pointer;
    .newslist-left {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .newslist-time{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .newslist-right {
      flex: 1;
    text-align: end;
      .num2 {
        color: #02bc7c;
      }
    }
  }
  .newslist:hover {
    background: #f4f7fd;
  }
}
</style>
