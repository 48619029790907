// 平台及账号发布信息数量情况
<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title
        ><Title>平台及账号发布信息数量情况</Title></template
      >
      <template v-slot:com>
        <ButtonSelection
          :buttonList="buttonList"
          @setButtonSelectFn="setButtonSelectFn"
        />
      </template>
    </TitleCard>
    <!-- 内容 -->
    <!--  内容栏  -->
    <el-card
      shadow="never"
      style="height: 306px"
      v-loading="specialActionsLoading"
    >
      <div id="Basic" style="height: 270px">我是echarts图</div>
    </el-card>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import ButtonSelection from "../../../components/ButtonSelection";
import Index from "@/api/index/index";
import Title from "../../../base/Title";
export default {
  components: {
    TitleCard,
    Title,
    ButtonSelection,
  },
  data() {
    return {
      specialActionsLoading: false, //加载效果
      buttonList: ["今天", "近7天", "近30天"],
      echartsX: [], //echartsX轴数据
      echartsY: [], //echartsY轴数据
    };
  },
  mounted() {
    this.setEcharts();
  },
  created() {
    this.getPlatformAccountRelease(0);
  },
  methods: {
    // 获取平台及账号发布信息数量情况
    async getPlatformAccountRelease(index) {
      this.specialActionsLoading = true;
      let data = {
        period: index,
      };
      this.echartsX = [];
      this.echartsY = [];
      const res = await Index.getPlatformAccountRelease(data);
      if (res.data.code == 200) {
        if (res.data.data) {
          res.data.data.forEach((x) => {
            this.echartsX.push(x.value);
            this.echartsY.push(x.num);
          });
          this.specialActionsLoading = false;
          this.setEcharts();
        } else {
          this.specialActionsLoading = false;
          this.setEcharts();
        }
      }
    },
    setButtonSelectFn(index) {
      this.getPlatformAccountRelease(index);
    },
    setEcharts() {
      if(!document.getElementById("Basic"))return
      const myChart = this.$echarts.init(document.getElementById("Basic"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          top: "16%",
          right: "3%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.echartsX,
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              rotate: -40, //值>0向右倾斜，值<0则向左倾斜
              textStyle: {
                color: "#C6C6C6",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D8D8D8",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              color: "#fff",
              fontSize: 11,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#C6C6C6",
              },
            },
          },
        ],
        series: [
          {
            name: "发布信息数量",
            type: "bar",
            data: this.echartsY,
            smooth: true,
            barWidth: 10, //柱子宽度
            barGap: 0.5, //柱子之间间距
            animationDuration: function (idx) {
              return idx * 500 + 1000;
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(230,104,78, 0.9)",
                    },
                    {
                      offset: 0.9,
                      color: "rgba(230,104,78, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(230,104,78, 0.9)",
                shadowBlur: 20,
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
                color: "#FF954D",
                opacity: 1,
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
</style>