<template>
  <div class="title-wrap">
    <div class="title">
       <slot name="title"></slot>
       <slot name="icon"></slot>
       <slot name="export"></slot>
    </div>
    <div class="com">
      <slot name="com"></slot>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Title", 
};
</script>
  
<style lang="scss" scoped>
.title-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 0; 
  height: 44.75px;
  .title{
    display: flex;
    align-items: center;
  }
}
</style>