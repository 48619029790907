export default function mixins() {
  return {
    data() {
      return {
        platformName: "平台类型",
        zhutiName: "主体类型",
        tabelLoading: false, // 表单加载状态
        exportLoading: false, // 导出按钮加载状态
      };
    },
    methods: {
      // 首页导出
      async exportHSOUYE(ApiFn) {
        this.exportLoading = true
        const Res = await ApiFn({});
        if (!Res) return;
        if (Res.data.code == 200) {
          this.$message({
            message: Res.data.data,
            type: "success",
          });
        } else {
          this.$message({
            message: Res.data.msg,
            type: "warning",
          });
        }
        this.exportLoading = false;
      },
      // 平台
      async handleClickPingTai(command) {
        this.tabelLoading = true;
        this.platformName = this.PTLXList.find((v) => v.value == command).label;
        await this.getInfo({ platformTypeList: [command] });
        this.tabelLoading = false;
      },
      // 主体
      async handleClickZhuTi(command) {
        this.tabelLoading = true;
        this.zhutiName = this.bodyLeiXin.find((v) => v.value == command).label;
        await this.getInfo({ bodyTypeList: [command] });
        this.tabelLoading = false;
      },
    },
  };
}
