<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard >
      <template v-slot:title><Title >近30日问题网站</Title></template>
       <template v-slot:export>
        <Ecport  v-loading="exportLoading" @click.native="exportHSOUYE(Index.exportThirtyDayProblemWebsite)"/>
        </template>
        <template v-slot:com>
        <TitleNext :desc="'更多'" @click.native="goMonitoringList" />
      </template>
    </TitleCard> 
    <el-table :header-cell-style="{'text-align':'center'}"  tooltip-effect
    :cell-style="{'text-align':'center'}"
 :data="tableData" style="width: 100%;" height="520">
      <el-table-column prop="accountName" label="问题网站名称"	> </el-table-column>
      <el-table-column prop="abnormalType" label="问题类型"> </el-table-column>
      <el-table-column prop="disposeTime" label="发现时间"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TitleCard from "../../../components/TitleCard";
import Title from "../../../base/Title";
import TitleNext from "../../../base/TitleNext";
import Index from "@/api/index/index";
import mixins from './minxin.js'
import Ecport from './ecport'
export default {
  mixins:[mixins()],
    components:{TitleCard,Title,TitleNext,Ecport},
  data() {
    return {
      tableData: [],
      Index
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
        this.tabelLoading = true
        const Res = await Index.thirtyDayProblemWebsite({
  "pageNo": "1",
  "pageSize": "10"

})
this.tableData = Res.data.data.list
this.tableData.forEach(v=> {
  v.accountName="开始进卡萨抗生素水水水水水水水水是顶顶顶顶顶大"
})
  this.tabelLoading = false
      // const list = { name: "问题名称", type: "问题类型", time: "2024-5-27" };
      // for (let inx = 0; inx < 10; inx++) {
      //   this.tableData.push(list);
      // }
    },
    goMonitoringList() {
       this.$router.push({
        path: "/Problem",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table td.el-table__cell {
  border-bottom: none !important;
}
.centered-table .cell{
     text-align: center;
  vertical-align: middle;
}
</style>
