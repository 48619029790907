<template>
  <div>
    <p class="title">
      <img src="../assets/img/icon/juxing.png" alt="" srcset="" /> <slot></slot>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  font-size: $font-size-title;
  color: $font-color-black;
  font-weight: $font-weight-500;
  font-family: $font-family-routine;
  img {
    margin-right: 3px;
    position: relative;
    top: 2px;
  }
}
</style>