var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newslist"},[(_vm.news.platformTypeName||_vm.news.platformName)?_c('div',{staticClass:"danger"},[_vm._v(_vm._s(_vm.news.platformTypeName ||_vm.news.platformName))]):_vm._e(),(
      _vm.news.warningType &&
        (_vm.news.warningType == 1 ||
          _vm.news.warningType == 2 ||
          _vm.news.warningType == 3 ||
          _vm.news.warningType == 4)
    )?_c('div',{staticClass:"infType"},[(_vm.news.warningType == 1)?_c('span',{staticStyle:{"background-color":"#f25764"}},[_vm._v("错")]):_vm._e(),(_vm.news.warningType == 2)?_c('span',{staticStyle:{"background-color":"#ff954d"}},[_vm._v("异")]):_vm._e(),(_vm.news.warningType == 3)?_c('span',{staticStyle:{"background-color":"#5c6ef9"}},[_vm._v("害")]):_vm._e(),(_vm.news.warningType == 4)?_c('span',{staticStyle:{"background-color":"#26ce93"}},[_vm._v("监")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"desc texthidden",domProps:{"innerHTML":_vm._s(_vm.news.title)},on:{"click":function($event){return _vm.goDetail(_vm.news)}}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.news.createTime))])])
}
var staticRenderFns = []

export { render, staticRenderFns }