// 按钮选择
<template>
  <div class="buttonBox">
    <div
      class="button-item m-l"
      :class="{'button-item-select':index==buttonIndex,'button-item-hui':BGColor}"
      v-for="(item, index) in buttonList"
      :key="index"
      @click="selectButtonSelect(index)"
    >{{item}}</div>
  </div>
</template>

<script>
export default {
  props:{
    buttonList:Array,
    defaultIndex:{
      type:Number,
      default :0
    },
    BGColor:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return { 
      buttonIndex:this.defaultIndex,
    }; 
  },
  created(){
    if(this.defaultIndex) this.selectButtonSelect(this.buttonIndex)
  },
  methods:{
    selectButtonSelect(index) {
      this.buttonIndex = index; // 改变选择时的UI
      this.$emit('setButtonSelectFn',index)  // 与父组件交互
    }
  }
};
</script>

<style lang="scss" scoped>
.buttonBox{
  width: auto;
  display: flex;
  .button-item{
    padding: 5px 15px 5px 15px;
    float: left;
    background: #FFFFFF ;
    color:#333746;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  .button-item-hui{
    background: #F5F5F5 ;
  }
  .button-item-select{
    background: linear-gradient(315deg,#f70,rgb(255, 151, 0));
    color: #fff;
  }
}
</style>