// 消息列表
<template>
  <div class="newslist">
    <div class="danger" v-if="news.platformTypeName||news.platformName">{{ news.platformTypeName ||news.platformName}}</div>
    <div
      class="infType"
      v-if="
        news.warningType &&
          (news.warningType == 1 ||
            news.warningType == 2 ||
            news.warningType == 3 ||
            news.warningType == 4)
      "
    >
      <span style="background-color: #f25764" v-if="news.warningType == 1">错</span>
      <span style="background-color: #ff954d" v-if="news.warningType == 2">异</span>
      <span style="background-color: #5c6ef9" v-if="news.warningType == 3">害</span>
      <span style="background-color: #26ce93" v-if="news.warningType == 4">监</span>
    </div>
    <div class="desc texthidden" v-html="news.title" @click="goDetail(news)"></div>
    <span class="time">{{news.createTime }}</span>
  </div>
</template>

<script>
export default {
  props: {
    news: Object,
  },
  created() {},
  methods:{
    goDetail(item){
      console.log(item.title);
      console.log(item.warningType);
      console.log(item);
      // return
      let obj = {
        1:"/errorDetail",
        2:"/questionDetail",
        3:"/harmfulDetail",
        4:"/monitorDetail",
      }
      this.$main.routeResolve(obj[item.warningType], item);
    },
  }
};
</script>

<style lang="scss" scoped>
.newslist {
  padding: 8px 0px;
  position: relative;
  display: flex;
  align-items: center;
  color: #333746;
  font-size: 14px;
  cursor: pointer;
  height: 54px;
  box-sizing: border-box;
  padding-left: 15px;
  .desc {
    display: block;
    width: 50%;
  }
  .time {
    position: absolute;
    right: 15px;
  }
  .danger {
    width: 60px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #e60b1e;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0px 10px;
    background-color: #ffe1e1;
    overflow: hidden;
  }
  .infType {
    width: 20px;
    height: 20px;
    margin: 0px 10px;
    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      color: #ffffff;
      text-align: center;
      line-height: 20px;
      border-radius: 4px;
    }
  }
}
.newslist:hover {
  background: #f4f7fd;
}
</style>
