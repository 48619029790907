
<template>
  <div class="titleNext">
    {{desc}}<i class="el-icon-arrow-right"></i>
  </div>
</template>

<script>
export default {
    title:" titlecard 里面右边点一下可以前进的 还有箭头的",
    props :{
      desc:{
        type:String,
        default:''
      }
    }
}
</script>

<style lang="scss" scoped>
.titleNext{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #F58030;
    font-weight: 800;
    font-family: $font-family-routine;
    cursor: pointer;
}
</style>